
import Vue from "vue";
import {
  communicationMapActions,
  communicationMapGetters,
  communicationMapMutations,
  communicationMapState
} from "@/store/modules/communication";
import { cloneDeep, omit, pick, has, noop } from "lodash";

import { objectDifference } from "@/helpers";
import { CountdownHelper } from "@/helpers/countdownHelper";

import { ICommunication } from "@/store/modules/communication/types";
import * as types from "@/store/mutation-types";

import * as dateFns from "date-fns";
interface SingleCommunicationData {
  saveRunning: boolean;
  editable: boolean;
  didInit: boolean;
  dateForCountdown: any;
  countDownTimer: null | CountdownHelper;
  resourceNotFound: boolean;
  errorMessage: string;
  message: string;
  deleteAction: boolean;
  nextRoute: any;
  inputsHasError: boolean;
}

export default Vue.extend({
  components: {
    SingleCommunicationFields: () => import("./SingleCommunicationFields.vue"),
    SingleEditCountdown: () => import("./SingleEditCountdown.vue"),
    saveChanges: () => import("@/components/Dialog/saveChanges.vue")
  },
  data(): SingleCommunicationData {
    return {
      didInit: false,
      saveRunning: false,
      editable: true,
      dateForCountdown: "",
      countDownTimer: null,
      resourceNotFound: false,
      errorMessage: "",
      message: "",
      deleteAction: false,
      nextRoute: "",
      inputsHasError: false
    };
  },
  mounted() {
    this.getCommunication(this.communicationId)
      .catch((e: any) => {
        this.errorMessage = e.message;
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(e);
      })
      .finally(() => {
        this.didInit = true;
      });
  },
  async beforeRouteLeave(to, from, next): Promise<void> {
    const hasProfileUpdates = Object.keys(this.updatedFields).length > 0;
    if (!this.deleteAction && hasProfileUpdates) {
      this.nextRoute = next;
      this.$modal.show("discard-changes-modal");
    } else {
      return next();
    }
  },
  watch: {
    communicationSaved: {
      deep: true,
      immediate: true,
      handler(communication: ICommunication | undefined) {
        if (communication) {
          const { status, deliveryDate, _id: communicationId } = communication;
          if (status === "scheduled" && deliveryDate) {
            this.editable = false;
            if (dateFns.isBefore(new Date(), deliveryDate)) {
              this.countDownTimer = new CountdownHelper(
                deliveryDate,
                noop,
                () =>
                  this.getCommunication(this.communicationId).catch(() => {})
              );
            }
          } else if (["dispatched", "completed"].includes(status)) {
            this.$router
              .replace(`/admin/communications/${communicationId}/completed`)
              .catch(() => {});
          }
        }
      }
    }
  },
  destroyed() {
    if (this.countDownTimer) {
      this.countDownTimer.stop();
    }
  },
  methods: {
    ...communicationMapActions([
      "getCommunication",
      "deleteCommunication",
      "updateCommunication"
    ]),
    ...communicationMapMutations({
      setEdit: types.SET_EDIT,
      editField: types.SET_EDIT_FIELD
    }),
    handleCommunicationInputError(event: boolean) {
      this.inputsHasError = event;
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "save":
          this.save();
          break;
        case "delete":
          this.deleteHandler();
      }
    },
    async beforeRouteLeaveSave() {
      try {
        this.$modal.hide("discard-changes-modal");
        await this.save();
        this.nextRoute();
      } catch (error) {
        this.$bugSnagClient.notify(error);
        this.nextRoute(false);
      }
    },
    async beforeRouteLeaveDiscardChanges() {
      try {
        this.$modal.hide("discard-changes-modal");
        this.nextRoute();
      } catch (error) {
        this.$bugSnagClient.notify(error);
        this.nextRoute(false);
      }
    },
    async deleteHandler(): Promise<void> {
      try {
        this.$modal.show("communicationsSingleDeleteModal");
        // return this.removeCommunication();
      } catch (error) {
        /* Do nothing */
      }
    },
    async removeCommunication(): Promise<void> {
      try {
        this.deleteAction = true;
        await this.deleteCommunication(this.communicationId);
        this.$appNotifySuccess("Communication Deleted Successfully");
        this.$router.push("/admin/communications").catch(() => {});
      } catch (error) {
        this.$emit("setError", error.message);
        this.$bugSnagClient.notify(error);
      }
    },
    async autoSave() {
      try {
        if (Object.keys(this.updatedFields).length === 0) {
          return;
        }
        const notAutoSaveFields = ["status", "sendImmediate", "deliveryDate"];
        this.saveRunning = true;
        const updates = cloneDeep(this.updatedFields);
        const notAutoSaveValues = pick(updates, notAutoSaveFields);
        const updatesExcludingFields = omit(updates, notAutoSaveFields);
        await this.updateCommunication({
          id: this.communicationId,
          update: updatesExcludingFields
        });

        for (const field of Object.keys(notAutoSaveValues)) {
          if (has(notAutoSaveValues, field)) {
            const fieldKey = field as
              | "status"
              | "sendImmediate"
              | "deliveryDate";
            this.editField({ key: fieldKey, value: notAutoSaveValues[field] });
          }
        }
      } catch (error) {
        this.errorMessage = error.message;
        this.$emit("setError", error.message);
        this.setEdit(this.communicationId);
        this.$bugSnagClient.notify(error);
      } finally {
        // this.stopCountDown();
        // this.startCountdown(1000);
        this.saveRunning = false;
      }
    },
    async save(silent = false): Promise<void> {
      if (Object.keys(this.updatedFields).length === 0) {
        return;
      }
      try {
        this.saveRunning = true;
        this.$emit("scrollTop");
        const updates = cloneDeep(this.updatedFields);
        await this.updateCommunication({
          id: this.communicationId,
          update: updates
        });
        if (!silent) {
          this.$emit("setMessage", "Communication Updated");
        }
        this.message = "Communication Updated";
      } catch (error) {
        this.errorMessage = error.message;
        this.$emit("setError", error.message);
        this.$bugSnagClient.notify(error);
      } finally {
        this.saveRunning = false;
        this.$modal.hide("discard-changes-modal");
      }
    },
    async editScheduledCommunication() {
      await this.updateCommunication({
        id: this.communicationId,
        update: {
          status: "draft"
        }
      });
    }
  },
  computed: {
    communicationId(): string {
      return this.$route.params.communicationId;
    },
    ...communicationMapGetters(["getCommunicationById"]),
    ...communicationMapState(["editing", "makingApiRequest"]),
    communicationSaved(): ICommunication | undefined {
      return this.getCommunicationById(this.communicationId);
    },
    updatedFields(): any {
      const toIgnore = ["alternateDeliveryMethods"];
      const original = this.getCommunicationById(this.communicationId);
      const edited = this.editing;
      const diff = objectDifference(edited || {}, original || {}, toIgnore);
      if (diff.template && Object.keys(diff.template).length === 0) {
        delete diff.template;
      }
      return diff;
    },
    canDelete(): boolean {
      return !!(
        this.communicationSaved &&
        this.communicationId &&
        ["draft", "scheduled"].includes(this.communicationSaved.status)
      );
    }
  }
});
