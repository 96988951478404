import {
  setIntervalAsync,
  clearIntervalAsync,
  SetIntervalAsyncTimer
} from "set-interval-async/dynamic";
import { isPast, parseISO } from "date-fns";

export class CountdownHelper {
  public countdownTimer: null | SetIntervalAsyncTimer = null;
  public countdownIntervalCallback: Function;
  public countdownCompleteCallback: Function;
  public countdownToDate: null | Date = null;

  constructor(
    countdownToDate: Date | string,
    countdownIntervalCallback: Function = () => {},
    countdownCompleteCallback: Function = () => {},
    POLLING_FREQUENCY = 1000
  ) {
    this.countdownToDate =
      typeof countdownToDate === "string"
        ? parseISO(countdownToDate)
        : countdownToDate;
    this.countdownTimer = null;
    this.countdownIntervalCallback = countdownIntervalCallback;
    this.countdownCompleteCallback = countdownCompleteCallback;

    this.start(POLLING_FREQUENCY);
  }

  private start(POLLING_FREQUENCY: number) {
    this.countdownTimer = setIntervalAsync(async () => {
      await this.countdownIntervalCallback.call(null);
      if (this.countdownToDate && isPast(this.countdownToDate)) {
        this.stop();
        await this.countdownCompleteCallback.call(null);
      }
    }, POLLING_FREQUENCY);
  }

  public stop() {
    if (this.countdownTimer) {
      clearIntervalAsync(this.countdownTimer);
      this.countdownTimer = null;
    }
  }
}
